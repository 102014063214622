<template>
  <!-- 
    画面: プランカテゴリ一覧テーブル
    用途: プランカテゴリ一覧の1件のデータを表示する
   -->
  <div>
    <!-- #region Table -->

    <table class="edge-type-table table table-sm table-striped table-bordered ">
      <thead class="bg-light text-body text-center">
        <tr>
          <th>{{ $t("planCategoryListPage.name")}}</th>
          <th width="120">{{ $t("planCategoryListPage.status")}}</th>
          <th width="120">{{ $t("planCategoryListPage.action")}}</th>
        </tr>
      </thead>
      <Draggable
        class="table-row-drag"
        v-model="tableItems"
        @end="onSortEnd"
        v-bind="dragOptions"
      >
        <transition-group
          type="transition"
          name="flip-list"
          tag="tbody"
        >
          <tr
            v-for="item in tableItems"
            :key="item.name"
          >
            <td>{{ item.name }}</td>
            <td class="text-center">
              <CSwitch
                class="swc sw-2"
                color="info"
                data-on="On"
                data-off="Off"
                variant="opposite"
                :labelOn='$t("common.valid")'
                :labelOff='$t("common.invalid")'
                :checked="item.status"
                @change="onStatusChanged()"
              />
            </td>
            <td class="text-center">
              <CButton
                class=""
                color="info"
                square
                size="sm"
                @click="onEditClicked(item.id)"
              ><i class="icon cil-pencil mr-1"></i>{{ $t("planCategoryListPage.editButton") }}</CButton>
            </td>
          </tr>
        </transition-group>
      </Draggable>
    </table>

    <!-- #region Alert -->
    <CAlert
      color="danger"
      class="mt-3 text-center"
      v-if="notFound"
    >
      表示可能なプランカテゴリはありません。
    </CAlert>
    <!-- #endregion Alert -->
  </div>
</template>

<script>
import axios from "axios";
import i18n from "@/i18n";
export default {
  name: "PlanCategoryListTable",
  data() {
    return {
      //#region Flag
      //#endregion Flag

      //#region Table

      /** 一覧表示データ */
      // tableItems: [],
      // TODO: 開発用
      tableItems: [],

      /** 一覧列名 */
      tableFields: [
        {
          key: "name",
          label: `${i18n.t("planCategoryListPage.name")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "status",
          label: `${i18n.t("planCategoryListPage.status")}`,
          _classes: "text-center bg-light",
          _style: "width: 12%",
        },
        {
          key: "action",
          label: `${i18n.t("planCategoryListPage.action")}`,
          _classes: "text-center bg-light",
          _style: "width: 12%",
        },
      ],

      //#region Response data

      response: [],

      //#endregion Response data
    };
  },
  computed: {
    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true; // TODO: 開発用
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },
    // create class for draggable row
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },

  methods: {
    //#region Event

    /** 有効無効切替 */
    onStatusChanged() {
      // TODO: 有効無効ステータス更新リクエスト
      this.postUpdateStatus();
    },

    /** 編集ボタン押下 */
    onEditClicked(id) {
      const planCategoryId = id;
      this.$router.push({
        path: `/operator/planCategory/${planCategoryId}/edit`,
      });
    },

    //#endregion Event

    //#region Request

    /** 有効無効ステータス更新 */
    postUpdateStatus() {
      // TODO:
    },

    //#endregion Request
    /** プランカテゴリ一覧取得 */
    reqGet() {

      //TODO:開発用
      const datas = [
        {
          id: "1",
          code: "plc01",
          name: "ファミリー向けプラン",
          description:"小さい子供連れの家族でも楽しめるプランです。",
          displayOrder:1,
          language:"ja",
          status: true,
        },
        {
          id: "2",
          code: "plc02",
          name: "夏限定プラン",
          description:"夏限定の食事メニューや施設が利用できるプランです。",
          displayOrder:2,
          language:"ja",
          status: true,
        },
        {
          id: "3",
          code: "plc03",
          name: "通年プラン",
          description:"年間を通していつでも利用できるプランです。",
          displayOrder:3,
          language:"ja",
          status: true,
        },
      ]

      this.response = datas;
      // 取得データを一覧表に流し込み
      this.pourTable(datas);
      this.loading = false;

      //TODO:API完成後にコメントアウト外す
      // const url = `/rest/hotel/${this.uid}/planCategory`;

      // axios
      //   .get(url, {
      //     headers: { Authorization: `Bearer ${this.$keycloak.token}` },
      //   })
      //   .then((resp) => {
      //     const datas = resp.data;
      //     console.log(datas);

      //     this.response = datas;

      //     // 取得データを一覧表に流し込み
      //     this.pourTable(datas);

      //     this.loading = false;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     this.PassError = err.response.status;
      //     this.loading = false;
      //   });
    },


    reqPutDisplayOrder() {
      const url = `/rest/hotel/${this.uid}/planCategory/order`;

      let list = [];
      this.tableItems.forEach((a) => list.push({ id: a.id, displayOrder: a.displayOrder }));
      const body = { list };

      axios
        .put(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.reqGet();
        })
        .catch((err) => {
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
        });
    },

    //#endregion Request

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          id: data.id,
          code: data.code,
          name: data.name,
          description:data.description,
          status: data.status,
        });
      });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    /**  */
    createTableItems(tableItem) {
      const tableItems = new Array(tableItem);
      return tableItems;
    },

    //draggable list
    onSortEnd(event) {
      console.log(event);
      this.tableItems.forEach((a, i) => {
        this.tableItems[i].order = i;
      });
    },

    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>
