<template>
  <!-- 
    画面: プランカテゴリ一覧
    用途: プランカテゴリ一覧を参照する
   -->
  <div>

    <!-- #region TODO -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("planCategoryListPage.pageTitle") }}</strong>
            <CButton
              color="info"
          
              @click="onCreateClicked()"
            >{{ $t("common.create") }}</CButton>
          </CCardHeader>
          <CCardBody>
            <PlanCategoryListTable />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<script>
// import axios from "axios";
import PlanCategoryListTable from "./components/ListTable";

export default {
  name: "PlanCategoryList",

  components: {
    PlanCategoryListTable,
  },

  props: {},

  data() {
    return {
      //#region Flag

      loading: false,

      //#endregion Flag

    }
  },

  computed: {

  },

  methods: {

    //#region Method

    /** 新規作成ボタン押下 */
    onCreateClicked() {
      this.$router.push({
        path: `/operator/planCategory/create`
      });
    },


    //#region Method

  }
};
</script>